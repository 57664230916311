import React, {useState, useEffect, useRef} from 'react';
import SplitType from 'split-type';
import classNames from 'classnames';

export const TextAnimationHeading = (props) => {
  const el = useRef(null);

  const _classNames = classNames({
    'text-animation-heading': true,
    [`as-${props.textStyle}`]: true
  });

  const initialDelay = props.delay || 0;
  const delay = props.faster ? 20 : 25;

  useEffect(() => {
    const text = new SplitType(el.current, { types: 'words, chars' });
    
    text.words.forEach((word, index) => {
      word.setAttribute('aria-hidden', true);
    });

    text.chars.forEach((letter, index) => {
      letter.setAttribute('aria-hidden', true);
      letter.style.animationDelay = `${initialDelay + (delay * index)}ms`
    });
  },[]);

  return(
    <span className={_classNames} ref={el} aria-label={props.text}>{ props.text }</span>
  )
}
