import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const Nav = (props) => {
  return (
    <nav className="nav nav--primary">
      <ul className="nav__items">
        {props.children}
      </ul>
    </nav>
  )
}

export const NavItem = (props) => {
  const className = classNames({
    'nav__item': true,
    'nav__item--has-subnav': props.dropdown
  });

  const [active, setActive] = useState(false);

  const isPartiallyActive = ({
    isPartiallyCurrent
  }) => {

    if (isPartiallyCurrent) {
      setActive(true);
    }
  }

  return (
    <li className={className}>
      <Link to={props.route} className={`nav__link ${active && "nav__link--active"}`} getProps={isPartiallyActive} activeClassName="nav__link--active" onClick={(e) => {
        if (props.dropdown) e.preventDefault();
      }}>
        <span>{props.title}</span>
      </Link>

      { props.dropdown && (
        <NavExpanded title={props.title}>
          {props.items.map((item, i) => {
            return <NavDropdownItem
              key={i}
              to={item.linksToPage?.slug || "#"}
              title={item.title}
              imageSrc={item.image.url}
            />
          })}
        </NavExpanded>
      )}
    </li>
  );
}

export const HamburgerMenu = (props) => {
  const className = classNames({
    'hamburger': true,
    'hamburger--open': props.isOpen
  })

  const click = () => {
    props.onClick(!props.isOpen);
  }

  return (
    <div className={className} onClick={click}>
      <span className="hamburger__line" />
      <span className="hamburger__line" />
      <span className="hamburger__line" />
    </div>
  )
}

export const NavExpanded = (props) => {
  let settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 4,
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1154,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  return (
    <div className="nav__item__subnav">
      <div className="nav__item__subnav__content">
        <div className="nav__item__subnav__content--inner">
          <Slider {...settings}>
            {props.children}
          </Slider>
        </div>
      </div>
      <div className="nav__item__subnav__skrim" />
    </div>
  )
}

export const NavDropdownItem = (props) => (
  <div className="nav__subnav__item">
    <Link to={props.to}>
      <h4 className="nav__subnav__item__title">{props.title}</h4>
      <div className="nav__subnav__item__image" aria-hidden="true">
        <img src={props.imageSrc} alt={props.imageAlt} />
      </div>
    </Link>
  </div>
)

export const NextArrow = (props) => {
  const { onClick, className } = props;
  return (
    <div
      className={"arrow-container arrow-container--right"}
      onClick={onClick}
    />
  );
}

export const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={"arrow-container arrow-container--left"}
      onClick={onClick}
    />
  );
}
