import React from 'react';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';

import './index.scss';

export default class Layout extends React.Component {
  componentDidMount () {
    // Smooth Scroll Pollyfill for Safari and older browsers
    smoothscroll.polyfill();
  }

  render = () => (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={this.props.favicon} />
      </Helmet>
      { this.props.children }
    </>
  )
}