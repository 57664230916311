import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import NaceroLogo from './logo.inline.svg';
import NaceroLogoCompact from './logo-compact.inline.svg';
import debounce from 'debounce';

import { Link } from 'gatsby';
import { Nav, NavItem, HamburgerMenu } from '../../components/navigation/index';
import { MobileOverlay } from '../../components/navigation/overlay-mobile';

export const GlobalHeader = (props) => {
  const prevScrollY = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [goingUp, setGoingUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      let same = prevScrollY.current === currentScrollY;
      let up = prevScrollY.current > currentScrollY;
      if (currentScrollY <= 0) up = false;
      if (!same) setGoingUp(up);
      prevScrollY.current = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 10);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  useEffect(() => {
    document.addEventListener('nav-click', () => setIsOpen(false));
  }, []);

  const handleClick = (state) => {
    setIsOpen(state);
  }

  const _classNames = classNames({
    'global-header': true,
    'global-header--white': props.white,
    'global-header--sticky': goingUp,
    'global-header--open': isOpen
  });

  return (
    <header className={_classNames}>
      <div className="global-header__wrapper">
        <Link aria-label="Return to homepage" title="logo" to={props.logoLinksTo?.slug}>
          <NaceroLogo className="global-header__logo global-header__logo--full" />
          <NaceroLogoCompact className="global-header__logo global-header__logo--compact" />
        </Link>

        <Nav className="nav-primary">
          {props.headerItems.map((item, i) => (
            <NavItem
              key={i}
              route={item.linksToPage?.slug || '#'}
              title={item.title}
              dropdown={(item.subnavigation.length !== 0)}
              items={item.subnavigation}
            />
          ))}
        </Nav>

        <HamburgerMenu onClick={handleClick} className="mobile-menu" isOpen={isOpen} />
      </div>
      <div className="global__header__overlay--mobile">
        {isOpen && <MobileOverlay items={props.headerItems} secondary={props.secondaryNavigation} />}
      </div>
    </header >
  )
}
